import React, {
  forwardRef,
  useState,
  useContext,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import styled from '@emotion/styled';
// import core components
import MenuBar from './MenuBar';
import QuillContainer from './QuillContainer';
import PhotoContainer from './PhotoContainer';
import { canvasHelper } from 'helpers';
import { MultiStepContext } from 'contexts';
import { multiStepConstants } from '../../../../constants';
import LoadingGif from '/static/gifs/loading.gif';
import { layoutTypeConstants } from '../../../../constants';
import { createCanvasToImage, quillToCanvasJson } from './utils';
// import * as htmlToImage from 'html-to-image';

const { STEP_INSIDE, STEP_REVIEW } = multiStepConstants;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ isMobile, orientation }) =>
    !isMobile && orientation == 'Landscape'
      ? `
  flex-direction:row;
  `
      : ``}
  &.active-step {
    display: flex;
  }
`;
const Inlay = styled.div`
  display: flex;
  ${({ isMobile }) =>
    isMobile
      ? `
  // overflow:hidden;
  `
      : ``}
  &.portrait {
    flex-direction: row;
    @media only screen and (max-width: 768px) {
      align-items: flex-end;
      height: 65vh;
    }
    > div:last-of-type {
      margin-left: 1px;
    }
  }
  &.landscape {
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      height: 40vh;
    }
    > div:last-of-type {
      margin-top: 1px;
    }
  }
  .loading-gif {
    margin: auto;
  }
`;
const CreateMessageDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0px;
`;
const Inside = forwardRef(
  ({ canvasRefs, savedCanvas, frontCanvas, isMobile }, ref) => {
    useImperativeHandle(ref, () => ({
      getLineItemJsons,
      getPreviewImgs,
      getCurrentLayout,
      getQuillJsons,
      handleChangeLayout,
      getCanvasJsons,
    }));
    const [defaultFontSize, setDefaultFontSize] = useState(
      isMobile ? '18px' : '22px'
    );
    const [defaultFontFamily, setDefaultFontFamily] = useState('Indie_Flower');
    const [insideLayout, setInsideLayout] = useState();
    const [activeLayout, setActiveLayout] = useState({
      activeDirection: 'right',
    });
    const handleChangeLayout = layouts => {
      if (layouts.length >= 2) {
        setInsideLayout(layouts);
        setActiveLayout({ activeDirection: 'right' });
      }
    };

    const effectiveWidth = frontCanvas && frontCanvas.width;
    const effectiveHeight = frontCanvas && frontCanvas.height;

    const {
      currentStep,
      pageContext: { outlay, title, category },
    } = useContext(MultiStepContext);

    const canvasMetadata = outlay ? JSON.parse(outlay) : null;
    const titleMetadata = title;
    const categoryMetadata = category;
    const { canvasWidth, canvasHeight } = canvasHelper.getCanvasSize(
      canvasMetadata
    ); // get width and height from the outlay json
    const orientation = canvasWidth > canvasHeight ? 'Landscape' : 'Portrait';

    const left_ref = useRef();
    const right_ref = useRef();

    const [vAlign, setVAlign] = useState(['center']);
    const [content, setContent] = useState([{}]);

    // const []
    const [vAlignRight, setVAlignRight] = useState(['top']);
    const [contentRight, setContentRight] = useState([{}]);
    useEffect(() => {
      if (savedCanvas && savedCanvas.length === 2 && savedCanvas[0]) {
        if (!canvasHelper.isCanvasJson(savedCanvas[0].json)) {
          setVAlign(savedCanvas[0].json.vAlign);
        }
        // setContent(savedCanvas[0].json.content);
        if (!canvasHelper.isCanvasJson(savedCanvas[1].json)) {
          setVAlignRight(savedCanvas[1].json.vAlign);
        }
        // setContentRight(savedCanvas[1].json.content);
      }
    }, [savedCanvas]);

    const getCurrentLayout = () => {
      return insideLayout;
    };
    const getQuillJsons = () => {
      try {
        if (insideLayout?.length !== 2) throw new Error('invalid layout');
        return [
          insideLayout[0][0] === layoutTypeConstants.TEXT
            ? JSON.stringify({ vAlign, content })
            : '',
          insideLayout[1][0] === layoutTypeConstants.TEXT
            ? JSON.stringify({ vAlign: vAlignRight, content: contentRight })
            : '',
        ];
      } catch (error) {
        return null;
      }
    };

    const onFontChange = font => {
      //console.log('here font', font);
      setDefaultFontFamily(font);
    };
    const onFontSizeChange = size => {
      //console.log('default font size changed', size);
      setDefaultFontSize(size);
    };

    const getCanvasJsons = async () => {
      try {
        let canvasJsons = await createCanvasToImage();
        if (insideLayout?.length !== 2) throw new Error('invalid layout');
        return [
          insideLayout[0][0] === layoutTypeConstants.TEXT
            ? canvasJsons.firstPage
            : canvasHelper.getJson(left_ref),
          insideLayout[1][0] === layoutTypeConstants.TEXT
            ? canvasJsons.secondPage
            : canvasHelper.getJson(right_ref),
        ];
      } catch (error) {
        return null;
      }
    };

    const getLineItemJsons = () => {
      try {
        if (insideLayout?.length !== 2) throw new Error('invalid layout');
        return [
          insideLayout[0][0] === layoutTypeConstants.TEXT
            ? quillToCanvasJson(vAlign, content, {
                width: canvasWidth,
                height: canvasHeight,
                margin: 0,
                count: insideLayout[0].length,
                canvasId: 'canvas-inside-left',
              })
            : canvasHelper.getJson(left_ref),
          insideLayout[1][0] === layoutTypeConstants.TEXT
            ? quillToCanvasJson(vAlignRight, contentRight, {
                width: canvasWidth,
                height: canvasHeight,
                margin: 0,
                count: insideLayout[1].length,
                canvasId: 'canvas-inside-right',
              })
            : canvasHelper.getJson(right_ref),
        ];
      } catch (error) {
        return null;
      }
    };

    const getPreviewImgs = () => {
      try {
        if (insideLayout?.length !== 2) throw new Error('invalid layout');
        return [
          insideLayout[0][0] === layoutTypeConstants.TEXT
            ? false
            : canvasHelper.getDataURL(left_ref),
          insideLayout[1][0] === layoutTypeConstants.TEXT
            ? false
            : canvasHelper.getDataURL(right_ref),
        ];
      } catch (error) {
        return null;
      }
    };

    const getSwipeOptions = () => {
      if (activeLayout.activeDirection === 'left') {
        return {
          canSwipUp: false,
          canSwipDown: orientation == 'Landscape' ? true : false,
          canSwipRight: orientation == 'Portrait' ? true : false,
        };
      } else {
        return {
          canSwipUp: orientation == 'Landscape' ? true : false,
          canSwipDown: false,
          canSwipLeft: orientation == 'Portrait' ? true : false,
        };
      }
    };

    const swipEvents = {
      onUpSwipe: () => {
        setActiveLayout(prevData => {
          return { ...prevData, activeDirection: 'left' };
        });
      },
      onDownSwipe: () => {
        setActiveLayout(prevData => {
          return { ...prevData, activeDirection: 'right' };
        });
      },
      onRightSwipe: () => {
        setActiveLayout(prevData => {
          return { ...prevData, activeDirection: 'right' };
        });
      },
      onLeftSwipe: () => {
        setActiveLayout(prevData => {
          return { ...prevData, activeDirection: 'left' };
        });
      },
    };
    // const generateImage=()=>{
    //   var domElement = document.querySelector('.quill');
    //   htmlToImage.toCanvas(domElement,{ cacheBust: true, })
    //     .then(function (canvas) {
    //       //console.log(canvas);
    //       // download(dataUrl, 'image.jpeg');
    //     })
    //     .catch(function (error) {
    //       console.error('oops, something went wrong!', error);
    //     });
    // }

    return (
      <Wrapper
        className={currentStep !== STEP_INSIDE ? 'hidden-step' : 'active-step'}
        isMobile={isMobile}
        orientation={orientation}
        id={'inside-step'}
      >
        <MenuBar
          isMobile={isMobile}
          width={`${canvasWidth}px`}
          orientation={orientation}
          onSetLayout={handleChangeLayout}
        />
        {isMobile && (
          <CreateMessageDiv>Create a message for the inside</CreateMessageDiv>
        )}
        <Inlay
          isMobile={isMobile}
          className={orientation.toLowerCase()}
          style={
            !isMobile
              ? { minWidth: `${canvasWidth}px`, minHeight: `${canvasHeight}px` }
              : {
                  justifyContent: 'center',
                }
          }
        >
          {insideLayout?.length === 2 ? (
            <>
              {insideLayout[0][0] == layoutTypeConstants.TEXT ? (
                <QuillContainer
                  title={titleMetadata}
                  category={categoryMetadata}
                  jsonData={savedCanvas[0]}
                  ref={left_ref}
                  isActive={activeLayout.activeDirection === 'left'}
                  vAlign={vAlign}
                  setVAlign={setVAlign}
                  orientation={orientation}
                  content={content}
                  setContent={setContent}
                  isMobile={isMobile}
                  getDataURL
                  swipEvents={swipEvents}
                  canSwip={getSwipeOptions()}
                  defaultFontSize={defaultFontSize}
                  defaultFontFamily={defaultFontFamily}
                  onFontChange={onFontChange}
                  onFontSizeChange={onFontSizeChange}
                  config={{
                    width: canvasWidth,
                    height: canvasHeight,
                    margin: 10,
                    count: insideLayout[0].length,
                    containerId: 'inside-left',
                    canvasId: 'canvas-inside-left',
                  }}
                />
              ) : (
                <PhotoContainer
                  id="photo-inside-left"
                  ref={left_ref}
                  isActive={activeLayout.activeDirection === 'left'}
                  isMobile={isMobile}
                  swipEvents={swipEvents}
                  canSwip={{ ...getSwipeOptions(), canSwipDown: false }}
                  width={isMobile ? effectiveWidth : canvasWidth}
                  height={isMobile ? effectiveHeight : canvasHeight}
                  count={insideLayout[0].length}
                  savedCanvas={savedCanvas[0]}
                />
              )}
              {insideLayout[1][0] == layoutTypeConstants.TEXT ? (
                <QuillContainer
                  title={titleMetadata}
                  category={categoryMetadata}
                  jsonData={savedCanvas[1]}
                  ref={right_ref}
                  orientation={orientation}
                  swipEvents={swipEvents}
                  canSwip={getSwipeOptions()}
                  isActive={activeLayout.activeDirection === 'right'}
                  vAlign={vAlignRight}
                  setVAlign={setVAlignRight}
                  defaultFontSize={defaultFontSize}
                  defaultFontFamily={defaultFontFamily}
                  onFontChange={onFontChange}
                  onFontSizeChange={onFontSizeChange}
                  content={contentRight}
                  setContent={setContentRight}
                  isMobile={isMobile}
                  config={{
                    width: canvasWidth,
                    height: canvasHeight,
                    margin: 10,
                    count: insideLayout[1].length,
                    containerId: 'inside-right',
                    canvasId: 'canvas-inside-right',
                  }}
                />
              ) : (
                <PhotoContainer
                  id="photo-inside-right"
                  ref={right_ref}
                  isActive={activeLayout.activeDirection === 'right'}
                  isMobile={isMobile}
                  swipEvents={swipEvents}
                  canSwip={{ ...getSwipeOptions(), canSwipDown: false }}
                  width={isMobile ? window.innerWidth - 70 : canvasWidth}
                  height={canvasHeight}
                  count={insideLayout[1].length}
                  savedCanvas={savedCanvas[1]}
                />
              )}
            </>
          ) : (
            <img
              className="loading-gif large"
              src={LoadingGif}
              alt="image loading"
            />
          )}
        </Inlay>
      </Wrapper>
    );
  }
);

export default Inside;
